export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      landing: {
        반복작업은: 'No code',
        망고피커: 'Browser Automation ',
        에게맡기세요: 'Tool',
        모든웹사이트또는웹앱에서웹사이트작업및반복작업을자동화합니다: 'A simple way to automate website tasks and repetitive operations!',
        크롬에무료로추가하기: 'Add to Chrome',
        크롬에무료로추가하기2: 'for FREE',
        매크로서비스: 'Automation Service',
        나만의자동화프로그램을만드세요: 'A few clicks to create your own automation program.',
        반복작업을자동화하여업무시간을: 'No more repetitive tasks!',
        획기적으로줄이세요: 'Minimum work hours to Maximize the results.',
        웹스크래핑: 'Web Scraping Service',
        데이터추출기능을통해웹에서원하는: 'You can easily retrieve desired information',
        정보를쉽게추출하고엑셀파일이나: 'via our data extraction feature', // 굵게 표현 누르면 링크 이동
        JSON파일로저장할수있습니다: 'then, simply save it to Excel or JSON files.',
        스토어오픈: 'Template Service',
        스토어에원하는액션을다운받아: 'Simply select the template for your needs',
        즉시사용해보세요: 'to get data immediately without coding efforts.',
        Pro로망고피커를150활용하세요: 'Utilize Mango Picker to its full extent with our Professional plan.',
        가장빠르게시작하는방법: 'The quickest way to get started',
        무료플랜: 'FREE plan',
        자동화5회일: 'Automations 5 times per day',
        스크래핑컬럼최대3개: 'Maximum 3 scraping columns',
        블럭최대개수10개회: 'Maximum 10 blocks per session',
        무료: 'FREE',
        전문가로거듭나세요: 'Become an expert',
        PRO플랜: 'Pro plan',
        무제한웹자동화: 'Unlimited web automation',
        무제한스크래핑컬럼: 'Unlimited scraping columns',
        무제한블럭: 'Unlimited blocks',
        이만원: '$15',
        월: '/ month',
        가격안내: 'Pricing',
        자주묻는질문: 'FAQs',
        망고피커는어떤서비스인가요: 'What is Mango Picker Service?',
        웹크롤링웹매크로서비스를쉽게사용할수있는서비스입니다: 'It is a service that makes web crawling and web macro services easy to use.', // Click for Demo 클릭하면 데모로 넘어가기
        무료로사용할수있나요: 'Free Trial available?',
        네사용가능합니다무료플랜으로도자유롭게사용하실수있습니다: 'Yes, it is available!', // Click for Free Trial. 링크로 넘어가기
        내데이터를제3자에게제공하나요: 'Is my data secure?',
        이용자동의없이데이터를외부에제공하지않습니다: 'Mango Picker does NOT share data with external parties without consent.',
        스토어란무엇인가요: 'What is Template Service?',
        다른사람이만들어놓은자동화를가져와즉시사용할수있는마켓플레이스입니다: 'You can easily access and immediately use automations created by others.',
        지금바로무료로사용해보세요: 'Try it for FREE right now.',
        오늘부터작업능률을10배향상해보세요: 'Improve your work efficiency by tenfold starting today.', // Click to Template Service 링크로 넘어가기
        무료로시작하기: 'Free Trial',
      },
      header: {
        가격안내: 'Pricing',
        로그인: 'Sign In',
        무료로추가하기: 'Free Trial',
      },
      footer: {
        블랙망고: '',
        대표자이인학: '',
        사업자번호6257200470: '',
        주소경기도화성시동탄중심상가2길86층비즈탑604219A호: '',
        대표전화050701784985: '',
        문의hellomangopickergmailcom: '',
        개인정보처리방침: 'Privacy Policy',
        이용약관: 'Terms of Service',
      },
      pricing: {
        필요에맞는요금제를선택하세요: 'Choose the plan that fits your needs.',
        주요기능: 'Key Features',
        기본블럭: 'Default blocks',
        Json내보내기: 'Export to JSON',
        웹자동화5회일: 'Automations 5 times per day',
        스크래핑컬럼최대3개: 'Maximum 3 scraping columns',
        블럭최대개수10개회: 'Maximum 10 blocks per session',
        스타터시작하기: 'Sign Up for Free',
        프로시작하기: 'Get Started',
        무료: 'FREE',
        스타터: 'Starter',
        Pro의모든기능: 'All Pro features +',
        사용자프로비저닝SCIM: 'User provisioning (SCIM)',
        고급보안과제어: 'Advanced security and control',
        감사로그: 'Audit log',
        무제한웹자동화: 'Unlimited web automation',
        무제한스크래핑컬럼: 'Unlimited scraping columns',
        무제한블럭: 'Unlimited blocks',
        문의하기: 'Contact Sales',
        엔터프라이즈: 'Enterprise',
        Starter의모든기능: 'All Starter features +',
        녹화블럭: 'Block recording',
        스토어기능: 'Template Service',
        스케줄링준비중: 'Scheduling (Coming Soon)',
        프로: 'PRO',
        가장많이사용: 'MOST POPULAR',
        이만원: '$15',
        월: '/month',
        사용량: 'Usage',
        웹자동화: 'web automation',
        일5회: '5 per day',
        무제한: 'Unlimited',
        스크래핑컬럼개수: 'Maximum number of scraping columns',
        일회최대3개: 'Up to 3 columns',
        블럭최대개수: 'Maximum number of blocks',
        일회최대10개: 'Up to 10 blocks',
        클릭블럭: 'click block',
        액션블럭: 'Action block',
        입력블럭: 'input block',
        추출블럭: 'extract block',
        페이징블럭: 'paging block',
        반복블럭: 'repeat block',
        블럭점프블럭: 'block jump',
        창닫기블럭: 'closing window',
        뒤로가기블럭: 'go back',
        대시보드: 'Dashboard',
        작업로그: 'Task log',
        JSON내보내기: 'Export to JSON',
        Excel내보내기: 'Export to Excel',
        관리와보안: 'Admin & security',
        SAMLSSO통합로그인: 'SAML Single Sign-On (SSO)',
        애널리틱스: 'Analytics',
        관리자콘텐츠검색: 'Admin content search',
        자주묻는질문: 'FAQs',
        무료로사용할수있나요: 'Free Trial available?',
        네사용가능합니다무료플랜으로도자유롭게사용하실수있습니다: 'Yes, it is available!', // Click for Free Trial. 링크로 넘어가기
        하지만더많은작업과다양한기능을사용하기위해서는프로플랜이필요합니다: 'However, for more tasks and additional features, you would need to subscribe to the Pro plan.',
        어떤결제방법을사용할수있나요: 'What payment methods are accepted?',
        신용카드체크카드로결제할수있습니다국내카드로는주요카드사모두지원하며해외카드는VISAMasterJCB카드가지원됩니다: 'You can pay with credit or debit cards. We support all major domestic and international credit or debit cards, VISA, Master, and JCB are supported.',
        구독은어떻게취소할수있나요: 'How can I cancel my subscription?',
        로그인설정빌링페이지에서구독취소를할수있습니다취소하기전에는구독이자동으로갱신됩니다: 'You can unsubscribe from the Login -> Settings -> Billing page. Your subscription is automatically renewed until you cancel it.',
        요금은언제청구되나요: 'When will I be billed?',
        Pro플랜은첫구입날청구되며월단위로청구됩니다예를들어2월24일에구매하였으면매달24일에결제가진행됩니다: 'The Pro plan is billed on the day of the initial purchase and is billed monthly. For example, if you purchased it on February 24th, you will be billed on the 24th of each subsequent month.',
        요금청구날짜를바꿀수있나요: 'Can I change the billing date?',
        바꿀수있습니다홈페이지에서바꿀수없으며hellomangopickergmailcom로이메일연락을주시면변경을도와드리겠습니다: 'Yes, it is possible. You can\'t change it on the website directly, but if you contact us at hello.mangopicker{\'@\'}gmail.com, we will assist you.',
        구독을취소하면어떻게되나요: 'What happens when I cancel my subscription?',
        자동으로Free버전으로변경되며Pro버전에서사용하지않은남은기간에대한환불은제공하지않습니다: 'Your subscription will automatically revert to the Free version, and there will be no refunds for any remaining unused period of the Pro plan.',
        오늘바로MangoPicker를: 'Try Mango Picker today', // 망고피커는 색을 주황색으로
        사용해보세요: 'Get started for free.',
        무료로시작하기: 'Free Trial',
      },
      login: {
        로그인: 'Sign In',
        이메일: 'Email',
        비밀번호: 'Password',
        비밀번호찾기: 'Forgot Password?',
        회원가입: 'Sign Up',
        이메일인증이완료되지않았습니다이메일을확인해주세요: 'Email authentication is not complete. Please check your email.',
        이메일혹은비밀번호가올바르지않습니다: 'Email or password is incorrect.',
      },
      signUp: {
        이름: 'Name',
        이름이비어있습니다: 'Please input your name.',
        이메일주소: 'Email address',
        비밀번호: 'Password',
        존재하지않는이메일주소입니다: 'Email address does not exist.',
        비밀번호는8자이상이어야합니다: 'Password must be at least 8 characters long.',
        회원가입: 'Sign Up',
        이미계정이있으신가요: 'Do you already have an account?',
        로그인: 'Sign In',
        서비스를이용함으로써귀하는본: 'By continuing with Google, Email, you agree to MangoPicker’s ',
        이용약관: 'Terms of Service',
        과: ' And ',
        개인정보처리방침: 'Privacy Policy',
        에동의하는것입니다: '.',
      },
      password: {
        비밀번호재설정: 'Reset Password',
        가입하신이메일로새비밀번호를설정할수있는링크가전송됩니다: 'You will receive a link to set a new password at the email address you used to sign up.',
        이메일을입력해주세요: 'Please enter your email.',
        전송: 'Send',
        로그인페이지로가기: 'Go to the login page.',
        존재하지않는이메일주소입니다: 'This email address does not exist.',
        전송완료이메일을확인해주세요: 'Please check your email for a confirmation message.',
        새비밀번호: 'New Password',
        새비밀번호확인: 'Confirm New Password',
        확인: 'Confirm',
        비밀번호는8자이상이어야합니다: 'The password must be at least 8 characters.',
        비밀번호가일치하지않습니다: 'The passwords do not match.',
        비밀번호를복구하려면이메일이필요합니다: 'To recover your password, an email is required.',
        이메일주소를확인할수없습니다형식이잘못되었습니다: 'We cannot verify the email address. The format is incorrect.',
        요청이이미완료되었습니다1분후에다시요청해주세요: 'The request has already been completed. Please make the request again in 1 minute',
      },
      dashboard_nav: {
        작업목록: 'Dashboard',
        스토어: 'Store',
        프로플랜으로업그레이드: 'Upgrade to Pro',
        로그아웃: 'Log out',
        셋팅: 'Settings',
      },
      setting: {
        메뉴: {
          셋팅: 'Setting',
          계정: 'Account',
          구독: 'Subscription',
        },
        회원탈퇴: {
          취소: 'cancel',
          회원탈퇴: 'Delete account',
          계정이영구삭제됩니다: 'Your account will be permanently deleted.',
          탈퇴후에는아이디와데이터는삭제되며복원할수없습니다게시판형서비스에남아있는게시글은탈퇴후삭제할수없습니다: 'After leaving, the ID and data are deleted and cannot be restored. Posts that remain in the bulletin type service cannot be deleted after leaving.',
          알림60초마다한번만요청할수있습니다: 'You can only request once every 60 seconds.',
          알림그동안서비스를이용해주셔서감사합니다: 'Thank you for using the service.',
          알림전송실패: 'Failed to send!',
          알림탈퇴완료: 'Deleted',
        },
        구독: {
          플랜: 'Plan',
          프로: 'Upgrade to ',
          업그레이드: 'Pro',
          업무능률200향상하기: 'Improve work efficiency by 200%',
          날짜: 'Date',
          설명: 'Service Name',
          서비스기간: 'Service Period',
          결제수단: 'Payment method',
          총합계: 'Amount due',
          신용카드: 'credit card',
          해지하기: 'Unsubscribe',
          정말해지하시겠어요: 'Are you sure to unsubscribe?',
          아쉬워요구독을해지하면다양한Pro의혜택과기회를잃게됩니다: 'If you cancel your subscription, you\'ll lose access to various Pro benefits and opportunities.',
          구독유지시새로운기능과업데이트를가장먼저경험하실수있어요: 'When you keep your subscription, you can have an early access to new features and updates.',
          더많은혜택을누릴수있는기회를놓치지마세요: 'Don\'t miss the opportunity to enjoy more benefits.',
          구독해지: 'Unsubscribe',
          취소: 'Cancel',
          구독해지성공: 'Unsubscribe Successful',
          다음결제일은000입니다1: 'Next payment date is ',
          다음결제일은000입니다2: '.',
          pro: 'Pro',
          free: 'Free',
        },
        계정: {
          알림전송실패: 'Failed to send!',
          알림저장실패: 'Failed to save! ',
          알림60초마다한번만요청할수있습니다: 'You can only request once every 60 seconds.',
          알림반복실패시이메일문의주세요: 'If you fail to repeat, please contact us by email.',
          알림이메일전송완료: 'Email sent complete!',
          알림비밀번호재설정: 'Reset Password',
          알림등록된메일로재설정링크를보냅니다: 'Send a reset link to a registered email.',
          알림받기: 'Send',
          알림취소: 'Cancel',
          알림저장완료: 'Save complete!',
          인증이완료되어변경할수없어요: 'The authentication is complete and cannot be changed.',
          이메일: 'Email',
          이름: 'Name',
          패스워드: 'Password',
          변경하기: 'Change password',
          언어: 'Language',
          계정삭제: 'Delete account',
          표준시간대: 'Time zone',
          현재시간: 'Current Time',
          저장하기: 'Save',
        },
      },
      업그레이드플랜: {
        프로플랜으로업그레이드: 'Upgrade to Pro',
        결제주기: 'Billing cycle',
        월간: 'Monthly',
        월: 'month',
        통화: 'Currency',
        업그레이드후통화변경이불가능합니다: 'You cannot change your currency after upgrade.',
        이청구됩니다: 'You’ll be charged',
        해당세금은결제시계산됩니다: 'Applicable taxes will be calculated at checkout.',
        결제계속진행: 'Continue to checkout',
        프로플랜: 'Pro Plan',
        아래의혜택을즉시사용하세요: 'Get more with the Pro plan',
        무제한웹자동화: 'Unlimited Web Automation',
        무제한스크래핑컬럼: 'Unlimited Scraping Column',
        무제한블럭: 'Unlimited block',
        녹화블럭: 'Record block',
        Excel내보내기: 'Export to Excel',
        스케줄링준비중: 'Scheduling (Preparing)',
        미국달러: 'US Dollar',
        한국원: 'Korean Won',
        구독결제완료: 'Subscription payment completed!',
      },
      대시보드: {
        상태: 'Status',
        작업: 'Task',
        시작시간: 'Start',
        작업시간: 'Duration',
        작업삭제: 'Delete',
        테이블포맷: 'Table format',
        JSON포맷: 'json format',
        엑셀다운로드: 'Download Excel',
        JSON다운로드: 'Download JSON',
        모든준비는끝났어요: 'Everything is ready.',
        아직실행을한번도하지않았네요: 'It hasn\'t been executed yet.',
        아래문서를보고빠르고간단한웹자동화프로그램을만들어보세요: 'Please take a look at the document below and create a fast and simple web automation program.',
        일단계: 'Step 1',
        확장자아이콘을클릭합니다: 'Click on the extension icon',
        이단계: 'Step 2',
        망고피커메뉴창에보이는액션을활용하여원하는동작을하나씩쌓아가세요: 'Utilize the actions displayed in the Mango Picker menu window to build up the desired actions one by one.',
        녹화: 'record',
        추출: 'extract',
        입력: 'input',
        클릭: 'click',
        반복: 'repeat',
        툴박스: 'toolbox',
        삼단계: 'Step 3',
        모두만들었으면실행버튼을누르세요: 'When you finish everything, press the \'Run\' button',
        시작: 'start',
        액션01: 'action01',
        컬럼명01: 'ColumnName01',
        실행: 'Run',
        이게다에요: 'You are set!',
        이제망고피커를활용하여자신만의웹자동화프로그램을만들어보세요: 'Now, create your own web automation program using MangoPicker.',
        삭제확인: 'Confirm to delete',
        선택한행1개를삭제하시겠습니까: 'Are you sure you want to delete the selected 1 row?',
        이작업은취소할수없습니다: 'This action cannot be undone.',
        취소: 'Cancel',
        삭제: 'Delete',
        삭제완료: 'Delete complete',
        삭제실패: 'Delete failed',
        에러가발생했습니다반복발생시고객센터로문의주세요: 'An error has occurred. If the issue persists, please contact customer support.', // 클릭을 하면 이메일 보내는 페이지로 이동
        분: 'm',
        초: 's',
        추출된데이터가없습니다: 'No data extracted.',
        이미지로보기: 'Show by Image',
        이미지다운로드: 'Download Image',
      },
      스토어: {
        검색어를력해주세요: 'Enter search terms',
      },
      이메일확인: {
        이메일확인: 'Email verification',
        이메일다시보내기: 'Resend email',
        이메일재전송완료: 'Email resend completed',
        으로메일을보냈습니다: 'We have sent an email to ',
        시작하려면이메일의내부링크를클릭해주세요: 'To get started, please click the internal link in the email.',
      },
      비밀번호재설정: {
        알림기존비밀번호와동일합니다: 'Matches the Current Password.',
        알림새로운비밀번호를다시입력해주세요: 'Please Re-enter the New Password.',
        알림비밀번호변경실패: 'Password Change Failed!',
        알림반복실패시이메일문의주세요: 'If you continue to experience issues, please contact us via email.', // 클릭시 이메일 보내는 링크로 이동
        알림비밀번호변경완료: 'Password Change Completed!',
        비밀번호재설정: 'Change password',
        새비밀번호: 'New password',
        비밀번호는8자이상이어야합니다: 'Password must be at least 8 characters long.',
        새비밀번호확인: 'Confirm new password',
        비밀번호가일치하지않습니다: 'The password does not match.',
        확인: 'Change password',
      },
      에러: {
        에러발생: 'An error has occurred.',
        반복발생시이메일로문의해주세요: 'In case of recurring failures, please contact hello.mangopicker{\'@\'}gmail.com.', // 링크 추가
        실패: 'Failure',
        전송실패: 'Transmission Failure!',
        에러60초마다한번만요청할수있습니다: 'You can only make a request once every 60 seconds.',
        이미가입된이메일이존재합니다: 'The email address is already registered.',
        결제내역불러오기실패: 'Failed to retrieve payment history',
        사용자불러오기실패: 'Failed to load user',
        대시보드불러오기실패: 'Failed to load dashboard data',
        플랜불러오기실패: 'Failed to load plan',
        업데이트실패: 'Update failed',
        이미프로플랜이어서결제창은나타나지않습니다: 'Since you already have Pro plan, the payment window does not appear',
        결제창실패: 'Payment window error',
      },
      알림: {
        알림: 'Notification',
        프로로업그레이드: 'Upgrade to Pro',
        취소: 'Cancel',
        고객님은무료플랜을이용중입니다: 'You are currently on the Free plan.',
        프로플랜으로업그레이드후이용가능합니다: 'You can use it after upgrading to Pro plan.', // 클릭시 pro plan 기는 페이지로 이동
        확인: 'Confirm',
        업그레이드성공: 'Upgrade successful!',
        프로플랜으로업그레이드왰습니다: 'You have been upgraded to Pro Plan!',
        망고피커아이콘을다시실행해Pro플랜을즐겨보세요: 'Restart the Mango Picker icon and enjoy the Pro Plan.',
        만약Pro플랜으로업그레이드되지않았다면: 'If the Pro Plan has not been applied, ',
        페이지새로고침: 'please refresh the page.',
        을해주세요: '',
      },

    },
    ko: {
      landing: {
        반복작업은: '반복 작업은 ',
        망고피커: '망고피커',
        에게맡기세요: '에게 맡기세요.',
        모든웹사이트또는웹앱에서웹사이트작업및반복작업을자동화합니다: '모든 웹사이트 또는 웹 앱에서 웹사이트 작업 및 반복 작업을 자동화합니다.',
        크롬에무료로추가하기: '크롬에 무료로 추가하기',
        크롬에무료로추가하기2: '',
        매크로서비스: '매크로 서비스',
        나만의자동화프로그램을만드세요: '나만의 자동화 프로그램을 만드세요.',
        반복작업을자동화하여업무시간을: '반복 작업을 자동화하여 업무 시간을',
        획기적으로줄이세요: '획기적으로 줄이세요.',
        웹스크래핑: '웹 스크래핑',
        데이터추출기능을통해웹에서원하는: '데이터 추출 기능을 통해 웹에서 원하는',
        정보를쉽게추출하고엑셀파일이나: '정보를 쉽게 추출하고 엑셀 파일이나',
        JSON파일로저장할수있습니다: 'JSON파일로 저장할 수 있습니다.',
        스토어오픈: '스토어 오픈',
        스토어에원하는액션을다운받아: '스토어에 원하는 액션을 다운받아',
        즉시사용해보세요: '즉시 사용해보세요.',
        Pro로망고피커를150활용하세요: 'Pro로 망고피커를 150% 활용하세요.',
        가장빠르게시작하는방법: '가장 빠르게 시작하는 방법',
        무료플랜: '무료 플랜',
        자동화5회일: '자동화5회/일',
        스크래핑컬럼최대3개: '스크래핑 컬럼 최대 3개',
        블럭최대개수10개회: '블럭 최대 개수 10개/회',
        무료: '무료',
        전문가로거듭나세요: '전문가로 거듭나세요',
        PRO플랜: 'PRO 플랜',
        무제한웹자동화: '무제한 웹 자동화',
        무제한스크래핑컬럼: '무제한 스크래핑 컬럼',
        무제한블럭: '무제한 블럭',
        이만원: '19,900원',
        월: '/ 월',
        가격안내: '가격 안내',
        자주묻는질문: '자주 묻는 질문',
        망고피커는어떤서비스인가요: '망고 피커는 어떤 서비스인가요?',
        웹크롤링웹매크로서비스를쉽게사용할수있는서비스입니다: '웹 크롤링, 웹 매크로 서비스를 쉽게 사용할 수 있는 서비스입니다.',
        무료로사용할수있나요: '무료로 사용할 수 있나요?',
        네사용가능합니다무료플랜으로도자유롭게사용하실수있습니다: '네, 사용 가능합니다. 무료 플랜으로도 자유롭게 사용 하실 수 있습니다.',
        내데이터를제3자에게제공하나요: '내 데이터를 제3자에게 제공하나요?',
        이용자동의없이데이터를외부에제공하지않습니다: '망고피커는 이용자 동의 없이 데이터를 외부에 제공하지 않습니다.',
        스토어란무엇인가요: '스토어란 무엇인가요?',
        다른사람이만들어놓은자동화를가져와즉시사용할수있는마켓플레이스입니다: '다른 사람이 만들어 놓은 자동화를 가져와 즉시 사용할 수 있는 마켓플레이스입니다.',
        지금바로무료로사용해보세요: '지금 바로 무료로 사용해보세요.',
        오늘부터작업능률을10배향상해보세요: '오늘부터 작업 능률을 10배 향상해 보세요.',
        무료로시작하기: '무료로 추가하기',
      },
      header: {
        가격안내: '가격 안내',
        로그인: '로그인',
        무료로추가하기: '무료로 추가하기',
      },
      footer: {
        블랙망고: '블랙망고',
        대표자이인학: '대표자: 이인학',
        사업자번호6257200470: '사업자번호: 625-72-00470',
        주소경기도화성시동탄중심상가2길86층비즈탑604219A호: '주소: 경기도 화성시 동탄중심상가2길 8, 6층 비즈탑 604-219A호',
        대표전화050701784985: '대표 전화: 0507-0178-4985',
        문의hellomangopickergmailcom: '문의: hello.mangopicker{\'@\'}gmail.com',
        개인정보처리방침: '개인정보처리방침',
        이용약관: '이용 약관',
      },
      pricing: {
        필요에맞는요금제를선택하세요: '필요에 맞는 요금제를 선택하세요.',
        주요기능: '주요 기능',
        기본블럭: '기본 블럭',
        Json내보내기: 'Json 내보내기',
        웹자동화5회일: '웹 자동화 5회/ 일',
        스크래핑컬럼최대3개: '스크래핑 컬럼 최대 3개',
        블럭최대개수10개회: '블럭 최대 개수 10개/회',
        스타터시작하기: '시작하기',
        프로시작하기: '시작하기',
        무료: '무료',
        스타터: '스타터',
        Pro의모든기능: 'Pro의 모든 기능 +',
        사용자프로비저닝SCIM: '사용자 프로비저닝(SCIM)',
        고급보안과제어: '고급 보안과 제어',
        감사로그: '감사 로그',
        무제한웹자동화: '무제한 웹 자동화',
        무제한스크래핑컬럼: '무제한 스크래핑 컬럼',
        무제한블럭: '무제한 블럭',
        문의하기: '문의하기',
        엔터프라이즈: '엔터프라이즈',
        Starter의모든기능: 'Starter의 모든 기능 +',
        녹화블럭: '녹화 블럭',
        스토어기능: '스토어 기능',
        스케줄링준비중: '스케줄링(준비중)',
        프로: '프로',
        가장많이사용: '가장 많이 사용',
        이만원: '19,900원',
        월: '/월',
        사용량: '사용량',
        웹자동화: '웹 자동화',
        일5회: '일 5회',
        무제한: '무제한',
        스크래핑컬럼개수: '스크래핑 컬럼 개수',
        일회최대3개: '1회 최대 3개',
        블럭최대개수: '블럭 최대 개수',
        일회최대10개: '1회 최대 10개',
        클릭블럭: '클릭 블럭',
        액션블럭: '액션 블럭',
        입력블럭: '입력 블럭',
        추출블럭: '추출 블럭',
        페이징블럭: '페이징 블럭',
        반복블럭: '반복 블럭',
        블럭점프블럭: '블럭 점프 블럭',
        창닫기블럭: '창닫기 블럭',
        뒤로가기블럭: '뒤로가기 블럭',
        대시보드: '대시보드',
        작업로그: '작업 로그',
        JSON내보내기: 'JSON 내보내기',
        Excel내보내기: 'EXCEL 내보내기',
        관리와보안: '관리와 보안',
        SAMLSSO통합로그인: 'SAML SSO(통합로그인)',
        애널리틱스: '애널리틱스',
        관리자콘텐츠검색: '관리자 콘텐츠 검색',
        자주묻는질문: '자주 묻는 질문',
        무료로사용할수있나요: '무료로 사용할 수 있나요?',
        네사용가능합니다무료플랜으로도자유롭게사용하실수있습니다: '네, 사용 가능합니다. 무료 플랜으로도 자유롭게 사용 하실 수 있습니다. ',
        하지만더많은작업과다양한기능을사용하기위해서는프로플랜이필요합니다: '하지만 더 많은 작업과 다양한 기능을 사용하기 위해서는 프로 플랜이 필요합니다.',
        어떤결제방법을사용할수있나요: '어떤 결제 방법을 사용할 수 있나요?',
        신용카드체크카드로결제할수있습니다국내카드로는주요카드사모두지원하며해외카드는VISAMasterJCB카드가지원됩니다: '신용카드, 체크카드로 결제할 수 있습니다. 국내카드로는 주요 카드사 모두 지원하며 해외카드는 VISA, Master, JCB카드가 지원됩니다.',
        구독은어떻게취소할수있나요: '구독은 어떻게 취소할 수 있나요?',
        로그인설정빌링페이지에서구독취소를할수있습니다취소하기전에는구독이자동으로갱신됩니다: '로그인 -> 설정 -> 빌링 페이지에서 구독 취소를 할 수 있습니다. 취소하기 전에는 구독이 자동으로 갱신됩니다.',
        요금은언제청구되나요: '요금은 언제 청구되나요?',
        Pro플랜은첫구입날청구되며월단위로청구됩니다예를들어2월24일에구매하였으면매달24일에결제가진행됩니다: 'Pro 플랜은 첫 구입날 청구되며 월단위로 청구됩니다. 예를 들어, 2월 24일에 구매하였으면 매달 24일에 결제가 진행됩니다.',
        요금청구날짜를바꿀수있나요: '요금 청구 날짜를 바꿀 수 있나요?',
        바꿀수있습니다홈페이지에서바꿀수없으며hellomangopickergmailcom로이메일연락을주시면변경을도와드리겠습니다: '바꿀 수 있습니다. 홈페이지에서 바꿀 수 없으며 hello.mangopicker{\'@\'}gmail.com로 이메일 연락을 주시면 변경을 도와드리겠습니다.',
        구독을취소하면어떻게되나요: '구독을 취소하면 어떻게 되나요?',
        자동으로Free버전으로변경되며Pro버전에서사용하지않은남은기간에대한환불은제공하지않습니다: '자동으로 Free 버전으로 변경되며 Pro 버전에서 사용하지 않은 남은 기간에 대한 환불은 제공하지 않습니다.',
        오늘바로MangoPicker를: '오늘 바로 Mango Picker를',
        사용해보세요: '사용해 보세요',
        무료로시작하기: '무료로 시작하기',
      },
      login: {
        로그인: '로그인',
        이메일: '이메일',
        비밀번호: '비밀번호',
        비밀번호찾기: '비밀번호 찾기',
        회원가입: '회원가입',
        이메일인증이완료되지않았습니다이메일을확인해주세요: '이메일 인증이 완료되지 않았습니다. 이메일을 확인해주세요.',
        이메일혹은비밀번호가올바르지않습니다: '이메일 혹은 비밀번호가 올바르지 않습니다.',

      },
      signUp: {
        이름: '이름',
        이름이비어있습니다: '이름이 비어있습니다.',
        이메일주소: '이메일 주소',
        비밀번호: '비밀번호',
        존재하지않는이메일주소입니다: '존재하지 않는 이메일 주소입니다.',
        비밀번호는8자이상이어야합니다: '비밀번호는 8자 이상이어야합니다.',
        회원가입: '회원가입',
        이미계정이있으신가요: '이미 계정이 있으신가요?',
        로그인: '로그인',
        서비스를이용함으로써귀하는본: '서비스를 이용함으로써, 귀하는 본 ',
        이용약관: '이용약관',
        과: ' 과 ',
        개인정보처리방침: '개인정보처리방침',
        에동의하는것입니다: '에 동의하는 것입니다.',
      },
      password: {
        비밀번호재설정: '비밀번호 재설정',
        가입하신이메일로새비밀번호를설정할수있는링크가전송됩니다: '가입하신 이메일로 새 비밀번호를 설정할 수 있는 링크가 전송됩니다.',
        이메일을입력해주세요: '이메일을 입력해주세요.',
        전송: '전송',
        로그인페이지로가기: '로그인 페이지로 가기',
        존재하지않는이메일주소입니다: '존재하지 않는 이메일 주소입니다.',
        전송완료이메일을확인해주세요: '전송 완료. 이메일을 확인해주세요.',
        새비밀번호: '새 비밀번호',
        새비밀번호확인: '새 비밀번호 확인',
        확인: '확인',
        비밀번호는8자이상이어야합니다: '비밀번호는 8자 이상이어야합니다.',
        비밀번호가일치하지않습니다: '비밀번호가 일치하지 않습니다.',
        비밀번호를복구하려면이메일이필요합니다: '비밀번호를 복구하려면 이메일이 필요합니다.',
        이메일주소를확인할수없습니다형식이잘못되었습니다: '이메일 주소를 확인할 수 없습니다. 형식이 잘못되었습니다.',
        요청이이미완료되었습니다1분후에다시요청해주세요: '요청이 이미 완료되었습니다. 1분 후에 다시 요청해주세요.',
      },
      dashboard_nav: {
        작업목록: '작업 목록',
        스토어: '스토어',
        프로플랜으로업그레이드: '프로 플랜으로 업그레이드',
        로그아웃: '로그아웃',
        셋팅: '셋팅',
      },
      setting: {
        메뉴: {
          셋팅: '셋팅',
          계정: '계정',
          구독: '구독',
        },
        회원탈퇴: {
          취소: '취소',
          회원탈퇴: '회원탈퇴',
          계정이영구삭제됩니다: '계정이 영구 삭제됩니다.',
          탈퇴후에는아이디와데이터는삭제되며복원할수없습니다게시판형서비스에남아있는게시글은탈퇴후삭제할수없습니다: '탈퇴 후에는 아이디와 데이터는 삭제되며 복원할 수 없습니다. 게시판형 서비스에 남아 있는 게시글은 탈퇴 후 삭제할 수 없습니다.',
          알림60초마다한번만요청할수있습니다: '60초마다 한 번만 요청할 수 있습니다.',
          알림그동안서비스를이용해주셔서감사합니다: '그동안 서비스를 이용해주셔서 감사합니다.',
          알림전송실패: '전송 실패!',
          알림탈퇴완료: '탈퇴 완료',
        },
        구독: {
          플랜: '플랜',
          프로: '프로',
          업그레이드: '업그레이드',
          업무능률200향상하기: '업무 능률 200% 향상하기',
          날짜: '날짜',
          설명: '설명',
          서비스기간: '서비스 기간',
          결제수단: '결제 수단',
          총합계: '총 합계',
          신용카드: '신용카드',
          해지하기: '해지하기',
          정말해지하시겠어요: '정말 해지하시겠어요?',
          아쉬워요구독을해지하면다양한Pro의혜택과기회를잃게됩니다: '아쉬워요! 구독을 해지하면 다양한 Pro의 혜택과 기회를 잃게 됩니다.',
          구독유지시새로운기능과업데이트를가장먼저경험하실수있어요: '구독 유지 시, 새로운 기능과 업데이트를 가장 먼저 경험하실 수 있어요.',
          더많은혜택을누릴수있는기회를놓치지마세요: '더 많은 혜택을 누릴 수 있는 기회를 놓치지 마세요.',
          구독해지: '구독해지',
          취소: '취소',
          구독해지성공: '구독 해지 성공',
          다음결제일은000입니다1: '다음 결제일은 ',
          다음결제일은000입니다2: ' 입니다.',
          pro: '프로',
          free: '무료',
        },
        계정: {
          알림전송실패: '전송 실패!',
          알림저장실패: '저장 실패! ',
          알림60초마다한번만요청할수있습니다: '60초마다 한 번만 요청할 수 있습니다.',
          알림반복실패시이메일문의주세요: '반복 실패 시 이메일 문의주세요.',
          알림이메일전송완료: '이메일 전송 완료!',
          알림비밀번호재설정: '비밀번호 재설정',
          알림등록된메일로재설정링크를보냅니다: '등록된 메일로 재설정 링크를 보냅니다.',
          알림받기: '받기',
          알림취소: '취소',
          알림저장완료: '저장 완료!',
          인증이완료되어변경할수없어요: '인증이 완료되어 변경 할 수 없어요.',
          이메일: '이메일',
          이름: '이름',
          패스워드: '패스워드',
          변경하기: '변경하기',
          언어: '언어',
          계정삭제: '계정 삭제',
          표준시간대: '표준 시간대',
          현재시간: '현재 시간',
          저장하기: '저장하기',
        },
      },
      업그레이드플랜: {
        프로플랜으로업그레이드: '프로 플랜으로 업그레이드',
        결제주기: '결제 주기',
        월간: '월간',
        월: '월',
        통화: '통화',
        업그레이드후통화변경이불가능합니다: '업그레이드 후 통화 변경이 불가능합니다.',
        이청구됩니다: '이 청구됩니다',
        해당세금은결제시계산됩니다: '해당 세금은 결제 시 계산됩니다.',
        결제계속진행: '결제 계속 진행',
        프로플랜: '프로 플랜',
        아래의혜택을즉시사용하세요: '아래의 혜택을 즉시 사용하세요',
        무제한웹자동화: '무제한 웹 자동화',
        무제한스크래핑컬럼: '무제한 스크래핑 컬럼',
        무제한블럭: '무제한 블럭',
        녹화블럭: '녹화 블럭',
        Excel내보내기: 'Excel 내보내기',
        스케줄링준비중: '스케줄링 (준비중)',
        미국달러: '미국 달러',
        한국원: '한국 원',
        구독결제완료: '구독 결제 완료!',
      },
      대시보드: {
        상태: '상태',
        작업: '작업',
        시작시간: '시작 시간',
        작업시간: '작업 시간',
        작업삭제: '작업 삭제',
        테이블포맷: '테이블 포맷',
        JSON포맷: 'json 포맷',
        엑셀다운로드: '엑셀 다운로드',
        JSON다운로드: 'json 다운로드',
        모든준비는끝났어요: '모든 준비는 끝났어요.',
        아직실행을한번도하지않았네요: '아직 실행을 한번도 하지 않았네요.',
        아래문서를보고빠르고간단한웹자동화프로그램을만들어보세요: '아래 문서를 보고 빠르고 간단한 웹 자동화 프로그램을 만들어보세요.',
        일단계: '1단계',
        확장자아이콘을클릭합니다: '확장자 아이콘을 클릭합니다.',
        이단계: '2단계',
        망고피커메뉴창에보이는액션을활용하여원하는동작을하나씩쌓아가세요: '망고 피커 메뉴창에 보이는 액션을 활용하여 원하는 동작을 하나씩 쌓아가세요.',
        녹화: '녹화',
        추출: '추출',
        입력: '입력',
        클릭: '클릭',
        반복: '반복',
        툴박스: '툴박스',
        삼단계: '3단계',
        모두만들었으면실행버튼을누르세요: '모두 만들었으면 “실행” 버튼을 누르세요.',
        시작: '시작',
        액션01: '액션01',
        컬럼명01: '컬럼명01',
        실행: '실행',
        이게다에요: '이게 다에요.',
        이제망고피커를활용하여자신만의웹자동화프로그램을만들어보세요: '이제 망고피커를 활용하여 자신만의 웹 자동화 프로그램을 만들어보세요.',
        삭제확인: '삭제 확인',
        선택한행1개를삭제하시겠습니까: '선택한 행 1개를 삭제하시겠습니까?',
        이작업은취소할수없습니다: '이 작업은 취소할 수 없습니다.',
        취소: '취소',
        삭제: '삭제',
        삭제완료: '삭제완료',
        삭제실패: '삭제 실패',
        에러가발생했습니다반복발생시고객센터로문의주세요: '에러가 발생했습니다. 반복 발생 시 고객센터로 문의해 주세요.',
        분: '분',
        초: '초',
        추출된데이터가없습니다: '추출된 데이터가 없습니다.',
        이미지로보기: '이미지로 보기',
        이미지다운로드: '이미지 다운로드',
      },
      스토어: {
        검색어를력해주세요: '검색어를 입력해주세요.',
      },
      이메일확인: {
        이메일확인: '이메일 확인',
        이메일다시보내기: '이메일 다시 보내기',
        이메일재전송완료: '이메일 재 전송 완료',
        으로메일을보냈습니다: '으로 메일을 보냈습니다.',
        시작하려면이메일의내부링크를클릭해주세요: '시작하려면 이메일의 내부 링크를 클릭해주세요.',
      },
      비밀번호재설정: {
        알림기존비밀번호와동일합니다: '기존 비밀번호와 동일합니다.',
        알림새로운비밀번호를다시입력해주세요: '새로운 비밀번호를 다시 입력해주세요.',
        알림비밀번호변경실패: '비밀번호 변경 실패!',
        알림반복실패시이메일문의주세요: '반복 실패 시 이메일 문의주세요.',
        알림비밀번호변경완료: '비밀번호 변경 완료!',
        비밀번호재설정: '비밀번호 재설정',
        새비밀번호: '새 비밀번호',
        비밀번호는8자이상이어야합니다: '비밀번호는 8자 이상이어야합니다.',
        새비밀번호확인: '새 비밀번호 확인',
        비밀번호가일치하지않습니다: '비밀번호가 일치하지 않습니다.',
        확인: '확인',
      },
      에러: {
        에러발생: '에러가 발생했습니다.',
        반복발생시이메일로문의해주세요: '반복 실패 시 hello.mangopicker{\'@\'}gmail.com로 문의주세요.',
        실패: '실패',
        전송실패: '전송 실패!',
        에러60초마다한번만요청할수있습니다: '60초마다 한 번만 요청할 수 있습니다.',
        이미가입된이메일이존재합니다: '이미 가입된 이메일이 존재합니다.',
        결제내역불러오기실패: '결제내역 불러오기 실패',
        사용자불러오기실패: '사용자 불러오기 실패',
        대시보드불러오기실패: '대시보드 불러오기 실패',
        플랜불러오기실패: '플랜 불러오기 실패',
        업데이트실패: '업데이트 실패',
        이미프로플랜이어서결제창은나타나지않습니다: '이미 프로플랜이어서 결제창은 나타나지 않습니다.',
        결제창실패: '결제창 실패',
      },
      알림: {
        알림: '알림',
        프로로업그레이드: '프로로 업그레이드',
        취소: '취소',
        고객님은무료플랜을이용중입니다: '고객님은 무료플랜을 이용중입니다.',
        프로플랜으로업그레이드후이용가능합니다: '프로플랜으로 업그레이드 후 이용가능합니다.',
        확인: '확인',
        업그레이드성공: '업그레이드 성공!',
        프로플랜으로업그레이드왰습니다: 'Pro 플랜으로 업그레이드 됐습니다!',
        망고피커아이콘을다시실행해Pro플랜을즐겨보세요: '망고피커 아이콘을 다시 실행 해 Pro플랜을 즐겨보세요.',
        만약Pro플랜으로업그레이드되지않았다면: '만약 Pro플랜으로 업그레이드 되지 않았다면 ',
        페이지새로고침: '페이지 새로고침',
        을해주세요: '을 해주세요.',
      },
    },
  },
}))
